import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuardService } from './auth-guard.service';
import { SimsComponent } from './pages/sims/sims.component';
import { CatalogComponent } from './pages/catalog/catalog.component';
import { DocsComponent } from './pages/docs/docs.component';
import { SupportComponent } from './pages/support/support.component';


const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./starter-home/starter-home.module').then(m => m.StarterHomeModule),
		canActivate: [AuthGuardService]
	},
	{
		path: 'overview',
		loadChildren: () => import('./pages/overview/overview.module').then(m => m.OverviewModule),
		canActivate: [AuthGuardService]
	},
	{
		path: 'sites',
			loadChildren: () => import('./pages/sites/sites.module').then(m => m.SitesModule),
			canActivate: [AuthGuardService]
	},
	{
		path: 'hubs',
			loadChildren: () => import('./pages/hubs/hubs.module').then(m => m.HubsModule),
			canActivate: [AuthGuardService]
	},
	{
		path: 'sims',
			loadChildren: () => import('./pages/sims/sims.module').then(m => m.SimsModule),
			canActivate: [AuthGuardService]
	},
	{
		path: 'users',
			loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule),
			canActivate: [AuthGuardService]
	},
	{
		path: 'docs',
		component: DocsComponent
	},
	{
		path: 'support',
		component: SupportComponent
	},
	{ 	path: 'login', 
			loadChildren: () => import('./site/login/login.module').then(m => m.LoginModule)
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
	exports: [RouterModule]
})
export class AppRoutingModule { }
