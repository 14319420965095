import { Component, HostBinding } from '@angular/core';
import { HesbeService } from '../hesbe.service';
import { User } from '../models/user';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
	// adds padding to the top of the document, so the content is below the header
	@HostBinding('class.bx--header') headerClass = true;

	public username: string;
	user: User;

	constructor(private hes: HesbeService) {
		this.hes.user.subscribe(x => this.user = x);
	}

	public get loggedIn() {
		 if(this.user) return true;
		 return false;
	}

	public logOut() {
		this.hes.logout();
	}

}
