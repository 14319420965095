import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from './../environments/environment';

import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from './models/user';
import { QueryValueType } from '@angular/compiler/src/core';

@Injectable({
  providedIn: 'root'
})
export class HesbeService {

 host = '';
 port = 443;  

 mapserverURL = 'https://mapserver.glowpro.shop/';
 url = 'http://192.168.22.231:1337/';
 token = '';
  
 headers: HttpHeaders = new HttpHeaders({
   'Content-type': 'application/json',
   'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjI3NjU2NjUzLCJleHAiOjE2MzAyNDg2NTN9.IpHU7IAQh04_1j2p4ALbYwTdu6RgaIq7nYr4Qm3uBiA' 
  });

  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;

  constructor(private router: Router, private http: HttpClient) { 
    this.host = environment.hes.host;
    this.port = environment.hes.port;
    if(this.port === 443) this.url = 'https://' + this.host + '/';
    if(this.port === 1337) this.url = 'http://' + this.host + ':1337/';
    
    this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
    this.user = this.userSubject.asObservable();

  }

  public get userValue(): User {
    this.userSubject.next(JSON.parse(localStorage.getItem('user')));
    return this.userSubject.value;
  }

  login(username: string, password: string) {
    let endpoint = this.url + 'auth/local';
    return this.http.post(endpoint, { identifier: username, password: password })
    .pipe(map((result: any) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      console.log("In pipe result " + JSON.stringify(result));

      localStorage.setItem('user', JSON.stringify(result.user));
      this.setLoggedInData(result.jwt);
      this.userSubject.next(result.user);
      return result.user;
    }));
  }

  logout() {
    this.clearLoggedInData();
    this.userSubject.next(null);
    this.router.navigate(['/login']);
  }

  public setLoggedInData(token) {
      localStorage.setItem('token', token);
      this.token = token;
      this.headers = new HttpHeaders({
        'Content-type': 'application/json',
        'Authorization': 'Bearer ' + token
      });
  }

  public clearLoggedInData() {
    localStorage.clear();
    this.token = '';
    this.headers = new HttpHeaders({
    });
  }

  searchFreeHubs(key) {
    
    let endpoint = this.url + 'availablehubs';
    endpoint = endpoint + '/?key=' + key;

    console.log(endpoint);

    return this.http.get(endpoint, {headers: this.headers});

  }

  // public setUser(user) {
  //   localStorage.setItem('user', JSON.stringify(user));
  //   this.user = user;
  // }

  installs(id?) {
    let endpoint = this.url + 'installs';
    if(id) {
      endpoint = endpoint + '/' + id;
    }
    return this.http.get(endpoint, {headers: this.headers});

  }

  sigfoxdata(id) {
    let endpoint = this.url + 'sigfoxes';
    if(id) {
      endpoint = endpoint + '?deviceId=' + id + '&data=1'
    }
    return this.http.get(endpoint, {headers: this.headers});
  }

  glowstatus(id) {
    let endpoint = this.url + 'glows';
    if(id) {
      endpoint = endpoint + '?deviceId=' + id;
    }
    return this.http.get(endpoint, {headers: this.headers});
  }

  hubs(id?) {
    let endpoint = this.url + 'hubs';
    if(id) {
      endpoint = endpoint + '/' + id;
    }
    return this.http.get(endpoint, {headers: this.headers});
  }

  sims() {
    let endpoint = this.url + 'sims';
    return this.http.get(endpoint, {headers: this.headers});
  }

  sites(id?) {
    let endpoint = this.url + 'sites';
    if(id) {
      endpoint = endpoint + '/' + id;
    }
    return this.http.get(endpoint, {headers: this.headers});
  }

  vthermes() {
    let endpoint = this.url + 'vthermes';
    return this.http.get(endpoint, {headers: this.headers});
  }

  /**
   * 
   * @param hubid 
   * @param event 
   */
  addHubEvent(hubid, event: any) {
    let endpoint = this.url + 'hubs' + '/' + hubid;
    
    console.log(endpoint);
    console.log(event);

    return this.http.post(endpoint, event, {headers: this.headers});
  }

  /**
   * 
   * @param site
   */
  saveSite(site: any) {
    let endpoint = this.url + 'sites';
    let sitedata = { 
      sitename: site.sitename, 
      location: {
        postcode: site.location.postcode, 
        geopoint: { 
          lat: site.location.geopoint.lat, 
          lng: site.location.geopoint.lng 
      }}};
    return this.http.post(endpoint, sitedata, {headers: this.headers});
  }

  /**
   * 
   * @param assignment.site
   * @param assignment.hub
   */
  saveSiteHub(assignment: any) {
    
    let endpoint = this.url + 'hubs/' + assignment.hub;
    let sitedata = { 
      id: assignment.hub,
      site: assignment.site.id
    };

    return this.http.put(endpoint, sitedata, {headers: this.headers});
  }

  /**
   * Add location to hub
   * @param hub 
   */

   saveLocationHub(assignment: any) {
    
    let endpoint = this.url + 'hubs/' + assignment.hub;

    let locationdata = { 
      location: {
        name: assignment.location.name,
        address1: assignment.site.location.address1,
        address2: assignment.site.location.address2,
        address3: assignment.site.location.address3,
        city: assignment.site.location.city,
        country: assignment.site.location.country,
        postcode: assignment.site.location.postcode,
        reference: assignment.location.reference, 
        geopoint: { 
          lat: assignment.site.location.geopoint.lat, 
          lng: assignment.site.location.geopoint.lng
        }
      }
    }

    return this.http.post(endpoint, locationdata, {headers: this.headers})

   }

  /**
   * Remove hub from a site so it can be reassigned
   * @param hubid
   */
  removeHubFromSite(hub) {
    
    console.log("removeHubFromSite");
    console.log(hub);

    let endpoint = this.url + 'hubs/' + hub.id;
    let sitedata = {
      id: hub.id,
      site: null
    }
    return this.http.put(endpoint, sitedata, {headers: this.headers});
  }


  /**
   * 
   * @param postcode 
   * @returns geopoint
   */
  postcodeLookup(postcode: string) {
    console.log(postcode);
    let endpoint = this.mapserverURL + 'postcodes/' + postcode;

    return this.http.get(endpoint);;
  }

}
