<ibm-header [route]="['/']" brand="GLOW" name="HES">
	<ibm-header-navigation *ngIf="loggedIn">
		<ibm-header-item routerLink="overview">Overview</ibm-header-item>
		<ibm-header-item routerLink="sites">Sites</ibm-header-item>
		<ibm-header-item routerLink="hubs">Hubs</ibm-header-item>
		<ibm-header-item routerLink="sims">Sims</ibm-header-item>
		<ibm-header-menu title="Users">
			<ibm-header-item>Engineers</ibm-header-item>
			<ibm-header-item>Operations</ibm-header-item>
		</ibm-header-menu>
	</ibm-header-navigation>
	<ibm-header-global *ngIf="loggedIn">
		<ibm-header-action (click)='logOut();' routerLink="account" title="action">
			<svg ibmIcon="user--avatar" size="20"></svg>
		</ibm-header-action>
		<!-- <ibm-header-action title="action">
			<svg ibmIcon="notification" size="20"></svg>
		</ibm-header-action> -->
	</ibm-header-global>
</ibm-header>
