import { Component } from '@angular/core';
import { HesbeService } from './hesbe.service';
import { User } from './models/user';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {

	public loggedin = true;
	user: User;

	constructor(private hes: HesbeService) { 
		this.hes.user.subscribe(x => this.user = x);	
	}

	public logout() {
		this.hes.logout();
	}


}
